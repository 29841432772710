<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="企微用户打标"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="5" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              任务ID：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-input v-model="searchData['taskId']" style="width: 200px" />
            </el-col>
            <el-col :span="2" style="text-align: end;">
              标签：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-select v-model="searchData['tagId']" placeholder="请选择">
                <el-option-group v-for="group in tagGroupList" :key="group.groupId" :label="group.groupName">
                  <el-option v-for="item in group.tag" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-option-group>
              </el-select>
              <el-button type="text" @click="refreshTagList()">刷新</el-button>
            </el-col>
            <el-col :span="2" style="text-align: end;">
              任务状态：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-select v-model="searchData['status']">
                <el-option
                  v-for="(item, index) in taskStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>

          </el-row>

          <el-row :gutter="10" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              任务创建时间：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-date-picker
                v-model="searchData['ctimeRange']"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/wxcpSetTagTask/add">
            <el-button type="primary">新建任务</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="任务ID" fixed="left" prop="id" :min-width="90">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoDetail(scope.row.id)">{{ scope.row.id }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="标签名" prop="tagName" :min-width="140" />
      <el-table-column label="标签组" prop="tagGroupName" :min-width="140" />
      <el-table-column label="状态" prop="status" :min-width="80">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">未执行</div>
          <div v-if="scope.row.status == 1">执行中</div>
          <div v-if="scope.row.status == 2">已执行</div>
        </template>
      </el-table-column>
      <el-table-column label="导入用户数" prop="allUserCount" :min-width="100" />
      <el-table-column label="覆盖用户数" prop="coveredUserCnt" :min-width="100" />
      <el-table-column label="设置成功用户数" prop="successCount" :min-width="120" />
      <el-table-column label="设置失败用户数" prop="failCount" :min-width="120" />
      <el-table-column label="创建时间" prop="ctime" :min-width="150" />
      <el-table-column label="备注" prop="remark" :min-width="200" />
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="removeHandle(scope.row)" v-if="scope.row.status == 0">删除</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="openLogDialog(scope.row)" v-if="scope.row.status == 2 || scope.row.status == 3">日志</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="execute( scope.row)" v-if="scope.row.status == 0 || scope.row.status == 3">执行</el-button>
        </template>
      </el-table-column>
    </my-table>


    <el-dialog
      title="任务执行日志"
      :visible.sync="logDialogVisible"
      :destroy-on-close="true"
      @closed="closedLogDialog"
      width="85%">
      <div style=" ">
        <my-table ref="logList" :data="logListData" @page="logPageHandler" :listLoading="logListLoading" >
          <template #header="params">
            <div  style="">
              <el-row style="padding-bottom: 5px">
                <el-col :span="2" style="text-align: end;">
                  手机号：
                </el-col>
                <el-col :span="6" style="justify-content: left;">
                  <el-input v-model="logSearchData['phone']" style="width: 200px" />
                </el-col>
                <el-col :span="2" style="text-align: end;">
                  状态：
                </el-col>
                <el-col :span="6" style="justify-content: left;">
                  <el-select v-model="logSearchData['status']">
                    <el-option
                      v-for="(item, index) in taskLogStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
                <el-col :span="4" >
                  <el-button @click="resetLogSearchData">重置</el-button>
                  <el-button @click="logPageHandler(params, 1)" type="primary" ref="logSearcheBtn">查询</el-button>
                </el-col>
              </el-row>
            </div>
          </template>
          <el-table-column label="用户手机号" prop="phone" fixed="left" :min-width="80" />
          <el-table-column label="用户ID" prop="userId" :min-width="140" />
          <el-table-column label="企微客户ID" prop="externalUserId" :min-width="230" />
          <el-table-column label="状态" prop="status" :min-width="50">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 0">未开始</div>
              <div v-if="scope.row.status == 1">成功</div>
              <div v-if="scope.row.status == 2">失败</div>
            </template>
          </el-table-column>
          <el-table-column label="执行时间" prop="mtime" :min-width="120" />
          <el-table-column label="followedUser" prop="followedUsers" :min-width="150" />
          <el-table-column label="备注" prop="remark" :min-width="200" />
        </my-table>
      </div>
    </el-dialog>

  </div>




</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        tagGroupList:[],
        taskStatusList: [
          {
            label: "未执行",
            value: 0
          },
          {
            label: "执行中",
            value: 1
          },
          {
            label: "已执行",
            value: 2
          }
        ],
        taskLogStatusList: [
          {
            label: "未执行",
            value: 0
          },
          {
            label: "成功",
            value: 1
          },
          {
            label: "失败",
            value: 2
          }
        ],
        listData: [],
        // 搜索数据
        searchData: {
          taskId: "",
          tagId: "",
          status: "",
          ctimeStart: "", // 任务创建时间-开始
          ctimeEnd: "", // 任务创建时间-结束
          ctimeRange: ""
        },
        listLoading: false ,//列表loading

        logDialogVisible:false,
        logListData: [],
        // 搜索数据
        logSearchData: {
          taskId: "",
          phone: "",
          status: ""
        },
        logListLoading: false ,//列表loading

      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getTaskList(params, num ? num : params.pageNum, params.pageSize);
    },
    removeHandle(row) {
      let url = 'wxcpSetTagTask/taskRemove';
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          taskId: row.id
        }).then(res => {
          if(res.code == 0){
            this.$message.success('删除成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    execute(row) {
      let url = 'wxcpSetTagTask/taskExecute';
      this.$confirm('是否确认运行？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          taskId: row.id
        }).then(res => {
          if(res.code == 0){
            this.$message.success('操作成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 跳转详情
    gotoDetail(taskId){
      this.$router.push(`/wxcpSetTagTask/view?taskId=` + taskId)
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        taskId: "",
        tagId: "",
        status: "",
        ctimeStart: "", // 任务创建时间-开始
        ctimeEnd: "", // 任务创建时间-结束
        ctimeRange: ""
      }
    },
    // 获取任务列表
    getTaskList(params,pageNum,pageSize) {
      if (this.searchData.ctimeRange) {
        this.searchData.ctimeStart = this.searchData.ctimeRange[0];
        this.searchData.ctimeEnd = this.searchData.ctimeRange[1];
      }

      this.listLoading = true;
      this.$store.dispatch('wxcpSetTagTask/taskList',{
        ...this.searchData,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

    // 标签列表
    getTagList(){
      this.$store.dispatch('wxcpSetTagTask/tagList',{}).then(res => {
        if(res.code == 0){
          this.tagGroupList = res.data.tagGroupList;
        }
      })
    },

    // 刷新标签
    refreshTagList() {
      this.$store.dispatch('wxcpSetTagTask/tagList',{isRefresh: 1}).then(res => {
        if(res.code == 0){
          this.tagGroupList = res.data.tagGroupList;
        }
      })
    },

    openLogDialog(row){
      this.logSearchData.taskId = row.id;
      this.logDialogVisible = true;
      this.$refs.logList.init();
    },

    closedLogDialog(row){
      this.logSearchData.taskId = "";
      this.logSearchData.phone = "";
      this.logSearchData.status = "";
      this.logListData=[];
    },

    // 重置搜索数据
    resetLogSearchData(e) {
      this.logSearchData.phone = "";
      this.logSearchData.status = "";
    },
    getTaskLogList(params,pageNum,pageSize) {
      this.logListLoading = true;
      this.$store.dispatch('wxcpSetTagTask/taskLogList',{
        ...this.logSearchData,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.logListData = res.data.list;
          params.render(res.data.total);
        }
        this.logListLoading = false;
      })
    },

    logPageHandler(params, num) {
      this.getTaskLogList(params, num ? num : params.pageNum, params.pageSize);
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTagList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.page_box {
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_nav_bar {
    width: 100%;
    background: #fff;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }
}
.handle_btn {
  padding: 0;
}
.channel_item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > .el-input:nth-child(1) {
    width: 260px;
  }
}
.channel_item:last-child {
  margin: 0;
}
.popularize_list_qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qrcode_img_src {
  position: absolute;
  top: -10000%;
  z-index: -10000;
}
::v-deep .el-table__header thead tr th:first-of-type,
.el-table__body tbody tr td:first-of-type {
  padding: 0 !important;
}
::v-deep .el-table__body tbody tr td:first-of-type {
  padding-left: 0px !important;
}
</style>
